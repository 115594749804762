<template>
  <div>
    <div
      class="content"
      style="
        border: 1px solid #e8e8e8;
        border-radius: 7px;
        padding-bottom: 20px;
        margin-bottom: 60px
      "
    >
      <div class="title">
        <div class="span"></div>
        储值金使用规则
      </div>
      <div style="margin: -5px 0 15px 20px; color: #909399">
        设置储值金可抵扣品类的比例，可填范围1-100
      </div>
      <div style="display: flex">
        <div style="margin-left: 30px">
          <el-tree
            class="tree-line"
            :data="attrList"
            node-key="goods_type_id"
            :default-expanded-keys="expandedKeys"
            :expand-on-click-node="false"
          >
            <div slot-scope="{ node, data }">
              <div style="width: 500px">
                <div style="line-height: 40px; float: left">
                  {{ node.label }}
                </div>
                <template v-for="(item, index) in storageList">
                  <div
                    class="storageBox"
                    :key="index"
                    v-if="data.goods_type_id === item.goods_type_id"
                  >
                    抵扣<el-input
                      v-model="item.storage_money_discount"
                      @input="
                        (val) => {
                          item.storage_money_discount = val.replace(
                            /[^\d]/g,
                            ''
                          );
                        }
                      "
                      style="height: 36px; width: 40%; margin: 0px 5px"
                    />%
                  </div>
                </template>
              </div>
            </div>
          </el-tree>
        </div>
        <!-- <div>
        <template v-for="(item, index) in storageList">
          </template
        >
      </div> -->
      </div>

      <!-- <div class="table" style="border-top: 1px solid #e8e8e8; margin: 0 15px">
      <el-table :data="attrList" stripe :height="600">
        <el-table-column
          prop="goods_type_name"
          label="品类"
          align="center"
        ></el-table-column>
        <el-table-column prop="action" label="可抵扣的比例设置" align="center">
          <template slot-scope="scope">
            <span>
              抵扣<el-input
                v-model="scope.row.storage_money_discount"
                style="width: 10%; margin: 5px 10px"
              />%
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div> -->
    </div>
    <div class="create">
      <el-button type="primary" @click="submit" :loading="loading"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { settingList, settingUpdate } from "@/api/member/memberList/index.js";
import { getAllGoodsType } from "@/api/goods/goodsConfig/classify";

export default {
  data() {
    return {
      attrList: [],
      expandedKeys: [],
      storageList: [],
      loading: false,
    };
  },
  created() {
    this.settingList();
  },
  methods: {
    /*
     *  储值金设置数据
     */
    settingList() {
      settingList().then((res) => {
        if (res.code === 1) {
          this.storageList = res.data;
          this.getGoodsTypeList();
        }
      });
    },
    /*
     *   获取品类下拉数据
     */
    getGoodsTypeList() {
      getAllGoodsType().then((res) => {
        if (res.code === 1) {
          this.handleArr(res.data);
          this.attrList = res.data;
        }
      });
    },
    handleArr(arr) {
      arr.forEach((item) => {
        this.expandedKeys.push(item.goods_type_id);
        item.label = item.goods_type_name;
        if (item.children) {
          this.handleArr(item.children);
        }
      });
    },

    /*
     *  保存
     */
    submit() {
      this.loading = true;
      var info = {};
      let isSuccess = false;
      this.storageList.forEach((item) => {
        if (Number(item.storage_money_discount > 100)) {
          this.$message({
            message:
              "品类：" + item.goods_type_name + "，设置的折扣不能超过100",
            type: "error",
          });
          this.loading = false;
          return (isSuccess = true);
        } else {
          info[item.goods_type_id] = item.storage_money_discount;
        }
      });
      if (!isSuccess) {
        settingUpdate({ storage_money_discount: JSON.stringify(info) }).then(
          (res) => {
            if (res.code === 1) {
              this.loading = false;
              this.$message({
                message: "更新成功！",
                type: "success",
              });
              this.settingList();
            }
          }
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.storageBox {
  height: 40px;
  line-height: 40px;
  float: right;
  text-align: right;
}
/deep/ .el-tree-node__label {
  font-size: 14px;
  &:nth-child(1) {
    font-weight: bold !important;
  }
}
/deep/ .el-tree-node__content {
  height: 50px;
  line-height: 50px;
}

.title {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 400;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.create {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 54px;
  padding-top: 15px;
  text-align: center;
  background-color: white;
  box-shadow: 0px 1px 3px 1px rgba(153, 153, 153, 0.1);
}
</style>